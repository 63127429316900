import request from "../../assets/js/Request.js";
// import qs from 'qs'

// 获取新闻列表
export const getNewList = (params) => {
  return request.get("getNewsList", params);
};
// 获取新闻详情
export const getNewDetails = (params) => {
  return request.get("getNewsOne?news_id="+params);
};
