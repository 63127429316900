<template>
  <div class="newsdetails">
    <div class="title">
      <span @click="goBack()">新闻咨询</span> / {{ newsdetail.title }}
    </div>
    <div class="contentBox">
      <div class="left">
        <h2>{{ newsdetail.title }}</h2>
        <div class="info">
          <span class="time">{{ newsdetail.addtime | getLocalTime }}</span>
          <span>阅读：{{ newsdetail.read_num }}</span>
        </div>
        <div class="border"></div>
        <!-- <div class="pic"><img :src="picURL + newsdetail.cover_img" /></div> -->
        <div class="newscontent" v-html="newsdetail.content"></div>
      </div>
      <!-- <ul class="right">
        <li
          v-for="item in newLists"
          :key="item.news_id"
          @click="ToDetailHandle(item)"
        >
          <div class="pic"><img :src="picURL+item.cover_img" alt="" /></div>
          <div class="text">
            <h3>{{ item.title }}</h3>
            <p class="time">{{ item.addtime | getLocalTime }}</p>
            <p>
              阅读: <span>{{ item.read_num }}</span>
            </p>
          </div>
        </li>
      </ul> -->
    </div>
  </div>
</template>
<script>
import { getNewDetails, getNewList } from "../API/new";
export default {
  data() {
    return {
      picURL: "http://www.gyycbj.com",
      newsdetail: {},
      newLists: [],
      loading: "",
      TheID: sessionStorage.getItem("NEWSID") || this.$route.params.id,
    };
  },
  beforeDestory() {
    clearTimeout();
    this.loading.close();
  },
  methods: {
    // 返回新闻列表
    goBack() {
      this.$router.push({
        name: "NewsInfo",
      });
    },
    // 获取新闻详情
    async getNewDetailHandle(id) {
      this.loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "hsla(0,0%,100%,.9)",
      });
      setTimeout(() => {
        this.loading.close();
      }, 5000);
      const { data: res } = await getNewDetails(id);
      if (res.code !== "0000") {
        return this.$message(res.msg);
      }
      this.newsdetail = res.data;
      this.loading.close();
    },
    // 获取新闻列表
    async getNewHandle() {
      const { data: res } = await getNewList();
      this.newLists = res.data;
      this.newLists = this.newLists.slice(0, 3);
    },
    // 新闻切换
    ToDetailHandle(item) {
      this.TheID = item.news_id;
      sessionStorage.setItem("NEWSID", item.news_id);
      this.getNewDetailHandle(this.TheID);
    },
  },
  created() {
    this.getNewDetailHandle(this.TheID);
    // this.getNewHandle();
  },
};
</script>
<style>
.newscontent img {
  width: 100% !important;
}
</style>
<style scoped lang="scss">
.newsdetails {
  margin-bottom: 180px;
  .title {
    font-size: 20px;
    color: #999999;
    padding: 30px 0 18px 0;
    margin: 0 auto;
    width: 1400px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media screen and (max-width: 540px) {
      padding: 14px 20px 14px 20px;
      text-align: center;
      width: 100%;
      font-size: 15px;
      box-sizing: border-box;
    }
    span {
      cursor: pointer;
    }
  }
  .contentBox {
    flex: 1;
    width: 1400px;
    background-color: #fff;
    padding: 40px 60px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    @media screen and (max-width: 540px) {
      display: block;
      width: 100%;
      padding: 0 14px;
    }
    .left {
      // width: 860px;
      width: 100%;
      margin-right: 60px;
      h2 {
        font-size: 34px;
        text-align: center;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media screen and (max-width: 540px) {
          font-size: 18px;
          text-align: center;
          padding-top: 15px;
        }
      }
      .info {
        color: #999999;
        font-size: 18px;
        text-align: center;
        padding: 20px 0 30px 0;
        @media screen and (max-width: 540px) {
          padding: 14px 0 20px 0;
          font-size: 13px;
        }
        .time {
          padding-right: 50px;
        }
      }
      .border {
        height: 1px;
        background-color: #eeeeee;
        width: 100%;
        margin-bottom: 30px;
        @media screen and (max-width: 540px) {
          display: none;
        }
      }
      .pic {
        height: 460px;
        width: 100%;
        margin-bottom: 60px;
        @media screen and (max-width: 540px) {
          height: 200px;
          margin-bottom: 20px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .newscontent {
        color: #666;
        font-size: 20px;
        text-align: center;
        img {
          width: 100% !important;
        }
        p {
          line-height: 43px;
          @media screen and (max-width: 540px) {
            font-size: 14px;
            text-indent: 0;
            line-height: 20px;
          }
        }
      }
    }
    .right {
      width: 358px;
      margin-top: 120px;
      li {
        padding: 30px 0;
        cursor: pointer;
        border-bottom: 1px solid #eeeeee;
        height: 100px;
        overflow: hidden;
        .pic {
          float: left;
          width: 130px;
          height: 100%;
          background-color: #7470fc;
          margin-right: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          float: left;
          width: 210px;
          h3 {
            overflow: hidden;
            white-space: nowrap;
            font-size: 18px;
            text-overflow: ellipsis;
          }
          p {
            color: #999999;
            font-size: 16px;
          }
          .time {
            padding: 18px 0 12px 0;
          }
        }
      }
    }
  }
}
</style>
